var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fullHeight"},[_c('v-card',{staticClass:"py-4 heightFull dashboard_bg"},[_c('h2',{staticClass:"text-center my-8"},[_vm._v(_vm._s(_vm.$t("document_statistics")))]),_c('v-row',{staticClass:"mx-0 mb-10",staticStyle:{"display":"flex","align-items":"flex-end"}},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"12","sm":"12","xs":"12"}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"3","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2",class:("elevation-" + (hover ? 16 : 3)),staticStyle:{"border-radius":"5px"},attrs:{"to":'/documents/list/' + (_vm.catalog.inbox ? _vm.catalog.inbox.originalName : '' ) + '/0'}},[_c('v-card-title',{staticClass:"card_title px-2"},[_vm._v(_vm._s(_vm.$t("document.inboxs")))]),_c('v-card-title',{staticClass:"card_title-number pt-0 pb-6"},[_vm._v(_vm._s((_vm.catalog.inbox ? _vm.catalog.inbox.new_count : '')))]),_c('v-divider',{staticClass:"ml-3 mr-10",attrs:{"color":"black"}}),_c('v-card-title',{staticClass:"card_title-sub--number pt-3 pb-0"},[_vm._v(" "+_vm._s(_vm.catalog.inbox ? _vm.catalog.inbox.count : '')+" ")]),_c('v-card-subtitle',{staticClass:"card_sub--title py-2 mx-0",attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.$t("dashboard_all")))])],1)]}}])})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"3","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2",class:("elevation-" + (hover ? 16 : 3)),staticStyle:{"border-radius":"5px"},attrs:{"to":'/documents/list/' + (_vm.catalog.outbox ? _vm.catalog.outbox.originalName : '') + '/0'}},[_c('v-card-title',{staticClass:"card_title px-2"},[_vm._v(_vm._s(_vm.$t("document.outboxs")))]),_c('v-card-title',{staticClass:"card_title-number pt-0 pb-6"},[_vm._v(_vm._s(_vm.catalog.outbox ? _vm.catalog.outbox.new_count : ''))]),_c('v-divider',{staticClass:"ml-3 mr-10",attrs:{"color":"black"}}),_c('v-card-title',{staticClass:"card_title-sub--number pt-3 pb-0"},[_vm._v(" "+_vm._s(_vm.catalog.outbox ? _vm.catalog.outbox.count : '')+" ")]),_c('v-card-subtitle',{staticClass:"card_sub--title py-2 mx-0",attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.$t("dashboard_all")))])],1)]}}])})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"3","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2",class:("elevation-" + (hover ? 16 : 3)),staticStyle:{"border-radius":"5px"},attrs:{"to":'/documents/list/' + (_vm.catalog.draft ? _vm.catalog.draft.originalName : '') + '/0'}},[_c('v-card-title',{staticClass:"card_title px-2"},[_vm._v(_vm._s(_vm.$t("document.drafts")))]),_c('v-card-title',{staticClass:"card_title-number pt-0 pb-6"},[_vm._v(_vm._s(_vm.catalog.draft ? _vm.catalog.draft.new_count : ''))]),_c('v-divider',{staticClass:"ml-3 mr-10",attrs:{"color":"black"}}),_c('v-card-title',{staticClass:"card_title-sub--number pt-3 pb-0"},[_vm._v(" "+_vm._s(_vm.catalog.draft ? _vm.catalog.draft.count : '')+" ")]),_c('v-card-subtitle',{staticClass:"card_sub--title py-2 mx-0",attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.$t("dashboard_all")))])],1)]}}])})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"3","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2",class:("elevation-" + (hover ? 16 : 3)),staticStyle:{"border-radius":"5px"},attrs:{"to":'/documents/list/' + (_vm.catalog.cancel ? _vm.catalog.cancel.originalName : '') + '/0'}},[_c('v-card-title',{staticClass:"card_title px-2"},[_vm._v(_vm._s(_vm.$t("document.cancels")))]),_c('v-card-title',{staticClass:"card_title-number pt-0 pb-6"},[_vm._v(_vm._s(_vm.catalog.cancel ? _vm.catalog.cancel.new_count : ''))]),_c('v-divider',{staticClass:"ml-3 mr-10",attrs:{"color":"black"}}),_c('v-card-title',{staticClass:"card_title-sub--number pt-3 pb-0"},[_vm._v(" "+_vm._s(_vm.catalog.cancel ? _vm.catalog.cancel.count : '')+" ")]),_c('v-card-subtitle',{staticClass:"card_sub--title py-2 mx-0",attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.$t("dashboard_all")))])],1)]}}])})],1)],1)],1)],1),(_vm.calendarSection)?_c('v-row',{staticClass:"mx-0 mb-10 top-chart justify-center"},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2 text-center",class:("elevation-" + (hover ? 8 : 3)),staticStyle:{"height":"200px"}},[_vm._v("qwre")])]}}],null,false,1284699845)})],1)],1):_vm._e(),(_vm.my_reysters.length > 0)?_c('h2',{staticClass:"text-center mb-6"},[_vm._v(" "+_vm._s(_vm.$t("reysters"))+" ")]):_vm._e(),(_vm.my_reysters.length > 0)?_c('v-row',{staticClass:"mx-0 mb-10 top-chart justify-center"},_vm._l((_vm.my_reysters),function(value,key){return _c('v-col',{key:key,staticClass:"pa-0",attrs:{"md":"3","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2 text-center",class:("elevation-" + (hover ? 16 : 3)),attrs:{"title":value['name_' + _vm.$i18n.locale],"to":'/dashboard-registry/' + value.id}},[_c('v-card',{staticClass:"white--text align-center elevation-0",staticStyle:{"position":"relative","border-bottom-right-radius":"0","border-bottom-left-radius":"0","height":"72px"},attrs:{"color":"green"}},[_c('v-card-title',{staticClass:"top-chart_title text-ellipsis px-8"},[_vm._v(_vm._s(value["name_" + _vm.$i18n.locale]))]),_c('v-btn',{staticStyle:{"color":"#0b4c84","margin-left":"-10px","font-size":"28px","font-weight":"600"},attrs:{"dark":"","absolute":"","fab":"","bottom":"","left":"","color":"#fff"}},[_vm._v(" "+_vm._s(value.documents_count)+" ")])],1),_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',{staticClass:"px-8 align-self-center",staticStyle:{"height":"60px"}},[_c('v-list-item-title',{staticClass:"top-chart_btn text-ellipsis text_nowrap mt-4",attrs:{"title":value['name_' + _vm.$i18n.locale]}},[_vm._v(" "+_vm._s(value.count)+" "+_vm._s(value.department["name_" + _vm.$i18n.locale])+" ")])],1)],1)],1)]}}],null,true)})],1)}),1):_vm._e(),_c('v-divider',{staticClass:"mb-10"}),_c('v-row',[_c('v-col',{staticClass:"mt-12",attrs:{"sm":"6"}},[(_vm.chartData2)?_c('PieChart',{attrs:{"data":_vm.chartData2}}):_vm._e()],1),_c('v-col',{staticClass:"mt-12",attrs:{"sm":"6"}},[(_vm.chartData2)?_c('DoughnutChart',{attrs:{"data":_vm.chartData2}}):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"300","hide-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("loadingText"))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }