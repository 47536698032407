<template>
  <div class="fullHeight">
    <v-card class="py-4 heightFull dashboard_bg">
      <!-- <div
        app
        v-if="alert_news"
        dark
        style="
          display: flex;
          align-items: center;
          width: 70%;
          height: 30px;
          vertical-align: middle;
          margin: auto;
        "
      >
        <marquee
          scrollamount="10"
          style="
            color: #163e72;
            font-family: monospace;
            font-weight: bold;
            letter-spacing: 2px;
            font-size: 18px;
          "
          >{{ alert_news }}</marquee
        >
      </div> -->
      <h2 class="text-center my-8">{{ $t("document_statistics") }}</h2>
      <v-row class="mx-0 mb-10" style="display: flex; align-items: flex-end">
        <v-col class="pa-0" md="12" sm="12" xs="12">
          <v-row class="mx-0">
            <v-col class="pa-0" md="3" sm="6" xs="12">
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="ma-2"
                    :class="`elevation-${hover ? 16 : 3}`"
                    style="border-radius: 5px"
                    :to="'/documents/list/' + (catalog.inbox ? catalog.inbox.originalName : '' ) + '/0'"
                  >
                    <v-card-title class="card_title px-2">{{
                      $t("document.inboxs")
                    }}</v-card-title>
                    <v-card-title class="card_title-number pt-0 pb-6">{{
                      (catalog.inbox ? catalog.inbox.new_count : '')
                    }}</v-card-title>
                    <v-divider class="ml-3 mr-10" color="black"></v-divider>
                    <v-card-title class="card_title-sub--number pt-3 pb-0">
                      {{ catalog.inbox ? catalog.inbox.count : '' }}
                    </v-card-title>
                    <v-card-subtitle
                      color="black"
                      class="card_sub--title py-2 mx-0"
                      >{{ $t("dashboard_all") }}</v-card-subtitle
                    >
                  </v-card>
                </template>
              </v-hover>
            </v-col>
            <v-col class="pa-0" md="3" sm="6" xs="12">
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="ma-2"
                    :class="`elevation-${hover ? 16 : 3}`"
                    style="border-radius: 5px"
                    :to="
                      '/documents/list/' + (catalog.outbox ? catalog.outbox.originalName : '') + '/0'
                    "
                  >
                    <v-card-title class="card_title px-2">{{
                      $t("document.outboxs")
                    }}</v-card-title>
                    <v-card-title class="card_title-number pt-0 pb-6">{{
                      catalog.outbox ? catalog.outbox.new_count : ''
                    }}</v-card-title>
                    <v-divider class="ml-3 mr-10" color="black"></v-divider>
                    <v-card-title class="card_title-sub--number pt-3 pb-0">
                      {{ catalog.outbox ? catalog.outbox.count : '' }}
                    </v-card-title>
                    <v-card-subtitle
                      color="black"
                      class="card_sub--title py-2 mx-0"
                      >{{ $t("dashboard_all") }}</v-card-subtitle
                    >
                  </v-card>
                </template>
              </v-hover>
            </v-col>
            <v-col class="pa-0" md="3" sm="6" xs="12">
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="ma-2"
                    :class="`elevation-${hover ? 16 : 3}`"
                    style="border-radius: 5px"
                    :to="'/documents/list/' + (catalog.draft ? catalog.draft.originalName : '') + '/0'"
                  >
                    <v-card-title class="card_title px-2">{{
                      $t("document.drafts")
                    }}</v-card-title>
                    <v-card-title class="card_title-number pt-0 pb-6">{{
                      catalog.draft ? catalog.draft.new_count : ''
                    }}</v-card-title>
                    <v-divider class="ml-3 mr-10" color="black"></v-divider>
                    <v-card-title class="card_title-sub--number pt-3 pb-0">
                      {{ catalog.draft ? catalog.draft.count : '' }}
                    </v-card-title>
                    <v-card-subtitle
                      color="black"
                      class="card_sub--title py-2 mx-0"
                      >{{ $t("dashboard_all") }}</v-card-subtitle
                    >
                  </v-card>
                </template>
              </v-hover>
            </v-col>
            <v-col class="pa-0" md="3" sm="6" xs="12">
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="ma-2"
                    :class="`elevation-${hover ? 16 : 3}`"
                    style="border-radius: 5px"
                    :to="
                      '/documents/list/' + (catalog.cancel ? catalog.cancel.originalName : '') + '/0'
                    "
                  >
                    <v-card-title class="card_title px-2">{{
                      $t("document.cancels")
                    }}</v-card-title>
                    <v-card-title class="card_title-number pt-0 pb-6">{{
                      catalog.cancel ? catalog.cancel.new_count : ''
                    }}</v-card-title>
                    <v-divider class="ml-3 mr-10" color="black"></v-divider>
                    <v-card-title class="card_title-sub--number pt-3 pb-0">
                      {{ catalog.cancel ? catalog.cancel.count : '' }}
                    </v-card-title>
                    <v-card-subtitle
                      color="black"
                      class="card_sub--title py-2 mx-0"
                      >{{ $t("dashboard_all") }}</v-card-subtitle
                    >
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col class="pa-0" md="12" sm="12" xs="12">
          <v-row class="mx-0">
            <v-col class="pa-0" md="3" sm="6" xs="12">
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="ma-2"
                    :class="`elevation-${hover ? 16 : 3}`"
                    style="border-radius: 5px"
                    to="/documents/list/expired/0"
                  >
                    <v-card-title class="card_title px-2">{{ $t("expired") }}</v-card-title>
                    <v-card-title class="card_title-number pt-0 pb-6">0</v-card-title>
                    <v-divider class="ml-3 mr-10" color="black"></v-divider>
                    <v-card-title class="card_title-sub--number pt-3 pb-0">
                      {{
                      report_by_date.expired
                      }}
                    </v-card-title>
                    <v-card-subtitle
                      color="black"
                      class="card_sub--title py-2 mx-0"
                    >{{ $t("dashboard_all") }}</v-card-subtitle>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
            <v-col class="pa-0" md="3" sm="6" xs="12">
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="ma-2"
                    :class="`elevation-${hover ? 16 : 3}`"
                    style="border-radius: 5px"
                    to="/documents/list/expired_done/0"
                  >
                    <v-card-title class="card_title px-2">{{ $t("expired_done") }}</v-card-title>
                    <v-card-title class="card_title-number pt-0 pb-6">0</v-card-title>
                    <v-divider class="ml-3 mr-10" color="black"></v-divider>
                    <v-card-title class="card_title-sub--number pt-3 pb-0">
                      {{
                      report_by_date.expired_done
                      }}
                    </v-card-title>
                    <v-card-subtitle
                      color="black"
                      class="card_sub--title py-2 mx-0"
                    >{{ $t("dashboard_all") }}</v-card-subtitle>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
            <v-col class="pa-0" md="3" sm="6" xs="12">
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="ma-2"
                    :class="`elevation-${hover ? 16 : 3}`"
                    style="border-radius: 5px"
                    to="/documents/list/processing/0"
                  >
                    <v-card-title class="card_title px-2">{{ $t("pending") }}</v-card-title>
                    <v-card-title class="card_title-number pt-0 pb-6">0</v-card-title>
                    <v-divider class="ml-3 mr-10" color="black"></v-divider>
                    <v-card-title class="card_title-sub--number pt-3 pb-0">
                      {{
                      report_by_date.pending
                      }}
                    </v-card-title>
                    <v-card-subtitle
                      color="black"
                      class="card_sub--title py-2 mx-0"
                    >{{ $t("dashboard_all") }}</v-card-subtitle>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
            <v-col class="pa-0" md="3" sm="6" xs="12">
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="ma-2"
                    :class="`elevation-${hover ? 16 : 3}`"
                    style="border-radius: 5px"
                    to="/documents/list/done/0"
                  >
                    <v-card-title class="card_title px-2">{{ $t("done") }}</v-card-title>
                    <v-card-title class="card_title-number pt-0 pb-6">0</v-card-title>
                    <v-divider class="ml-3 mr-10" color="black"></v-divider>
                    <v-card-title class="card_title-sub--number pt-3 pb-0">
                      {{
                      report_by_date.done
                      }}
                    </v-card-title>
                    <v-card-subtitle
                      color="black"
                      class="card_sub--title py-2 mx-0"
                    >{{ $t("dashboard_all") }}</v-card-subtitle>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>-->
      </v-row>
      <v-row class="mx-0 mb-10 top-chart justify-center" v-if="calendarSection">
        <v-col class="pa-0" md="12">
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                class="ma-2 text-center"
                :class="`elevation-${hover ? 8 : 3}`"
                style="height: 200px"
                >qwre</v-card
              >
            </template>
          </v-hover>
        </v-col>
      </v-row>

      <h2 class="text-center mb-6" v-if="my_reysters.length > 0">
        {{ $t("reysters") }}
      </h2>
      <v-row
        class="mx-0 mb-10 top-chart justify-center"
        v-if="my_reysters.length > 0"
      >
        <v-col
          class="pa-0"
          md="3"
          sm="6"
          xs="12"
          v-for="(value, key) in my_reysters"
          :key="key"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                class="ma-2 text-center"
                :class="`elevation-${hover ? 16 : 3}`"
                :title="value['name_' + $i18n.locale]"
                :to="'/dashboard-registry/' + value.id"
              >
                <v-card
                  color="green"
                  class="white--text align-center elevation-0"
                  style="
                    position: relative;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    height: 72px;
                  "
                >
                  <v-card-title class="top-chart_title text-ellipsis px-8">{{
                    value["name_" + $i18n.locale]
                  }}</v-card-title>
                  <v-btn
                    class
                    dark
                    absolute
                    fab
                    bottom
                    left
                    color="#fff"
                    style="
                      color: #0b4c84;
                      margin-left: -10px;
                      font-size: 28px;
                      font-weight: 600;
                    "
                  >
                    {{ value.documents_count }}
                    <!-- <v-icon>mdi-plus</v-icon> -->
                  </v-btn>
                </v-card>
                <v-list-item class="px-0">
                  <v-list-item-content
                    class="px-8 align-self-center"
                    style="height: 60px"
                  >
                    <v-list-item-title
                      :title="value['name_' + $i18n.locale]"
                      class="top-chart_btn text-ellipsis text_nowrap mt-4"
                    >
                      {{ value.count }}
                      {{ value.department["name_" + $i18n.locale] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
      <v-divider class="mb-10"></v-divider>
      <!-- <v-row>
        <v-col sm="6" class="pl-6">
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <v-card-title class="pt-0" style="font-size: 18px"
              >{{ $t("total_user") }}:
              <span style="font-size: 18px; padding-left: 10px">{{
                user_all_count.user_all_count
                  ? user_all_count.user_all_count
                  : ""
              }}</span></v-card-title
            >
            <v-card-title class="pt-0" style="font-size: 18px"
              >{{ $t("user_eri") }}:
              <span style="font-size: 18px; color: green; padding-left: 10px">{{
                user_eri.user_eri ? user_eri.user_eri : ""
              }}</span></v-card-title
            >
            <v-card-title class="pt-0" style="font-size: 18px"
              >{{ $t("user_ad") }}:
              <span style="font-size: 18px; color: red; padding-left: 10px"
                >{{ user_ad.user_ad ? user_ad.user_ad : "" }}
              </span></v-card-title
            >
          </div>

          <template>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{ $t("total_user") }}</td>
                    <td style="font-size: 18px; padding-left: 10px">
                      {{
                        user_all_count.user_all_count
                          ? user_all_count.user_all_count
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("user_eri") }}</td>
                    <td
                      style="font-size: 18px; color: green; padding-left: 10px"
                    >
                      {{ user_eri.user_eri ? user_eri.user_eri : "" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("user_ad") }}</td>
                    <td style="font-size: 18px; color: red; padding-left: 10px">
                      {{ user_ad.user_ad ? user_ad.user_ad : "" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-col>
        <v-col sm="6" class="pr-6">
          <template>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{ $t("total_documentation") }}</td>
                    <td style="font-size: 18px; padding-left: 10px">
                      {{ all_count.all_count ? all_count.all_count : "" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("EDS_with") }}</td>
                    <td style="font-size: 18px; padding-left: 10px">
                      {{ doc_eri.doc_eri ? doc_eri.doc_eri : "" }} -
                      <span class>
                        {{
                          (
                            (100 * doc_eri.doc_eri) /
                            all_count.all_count
                          ).toFixed(2) + " %"
                        }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("EDS_without") }}</td>
                    <td style="font-size: 18px; padding-left: 10px">
                      {{ doc_ad.doc_ad ? doc_ad.doc_ad : "" }} -
                      <span class>
                        {{
                          ((100 * doc_ad.doc_ad) / all_count.all_count).toFixed(
                            2
                          ) + " %"
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-col>
      </v-row> -->

      <!-- <v-row>
        <v-col sm="12" class="mt-12">
          <template>
            <v-simple-table v-if="!loading">
              <template v-slot:default>
                <thead class="notHover">
                  <tr id="notHover">
                    <th class="text-center" rowspan="2">
                      {{ $t("total_documentation") }}
                    </th>

                    <th class="text-center" colspan="2">
                      {{ $t("EDS_with") }}
                    </th>
                    <th class="text-center" colspan="2">
                      {{ $t("EDS_without") }}
                    </th>
                  </tr>
                  <tr>
                    <th class="text-center">{{ $t("number") }}</th>
                    <th class="text-center">{{ $t("percent") }}</th>
                    <th class="text-center">{{ $t("number") }}</th>
                    <th class="text-center">{{ $t("percent") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ all_count.all_count ? all_count.all_count : "" }}
                    </td>
                    <td class="text-center">
                      {{ doc_eri.doc_eri ? doc_eri.doc_eri : "" }}
                    </td>
                    <td class="text-center">
                      {{
                        ((100 * doc_eri.doc_eri) / all_count.all_count).toFixed(
                          2
                        ) + " %"
                      }}
                    </td>
                    <td class="text-center">
                      {{ doc_ad.doc_ad ? doc_ad.doc_ad : "" }}
                    </td>
                    <td class="text-center">
                      {{
                        ((100 * doc_ad.doc_ad) / all_count.all_count).toFixed(
                          2
                        ) + " %"
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-col>
      </v-row>-->
      <!-- <v-divider class="my-10"></v-divider> -->
      <v-row>
        <v-col sm="6" class="mt-12">
          <PieChart v-if="chartData2" :data="chartData2" />
        </v-col>
        <v-col sm="6" class="mt-12">
          <DoughnutChart v-if="chartData2" :data="chartData2" />
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios").default;
import DoughnutChart from "@/components/DoughnutChart";
import PieChart from "@/components/PieChart";
import Profile from "@/components/Profile";
import Cookies from "js-cookie";

export default {
  components: {
    PieChart,
    DoughnutChart,
    Profile,
  },
  name: "Home",
  data() {
    return {
      departments: [],
      employees: [],
      all_count: [],
      doc_eri: [],
      doc_ad: [],
      user_all_count: [],
      user_eri: [],
      user_ad: [],
      calendarSection: false,

      report_by_date: {
        expired: 0,
        expired_done: 0,
        done: 0,
        pending: 0,
      },
      catalog: {
        inbox: { count: 0, new_count: 0 },
        outbox: { count: 0, new_count: 0 },
        draft: { count: 0, new_count: 0 },
        cancel: { count: 0, new_count: 0 },
      },
      filter: {
        today: null,
        yesterday: null,
        week: null,
        month: null,
        between_dates: null,
        department_id: "",
        employee_id: "",
      },
      my_reysters: [],
      document_types: [],
      document_lists: [],
      source: "/",
      count_document: 0,
      loading: false,
      menu: "",
      start: "",
      select: ["4 Menendjer"],
      states: ["Alabama", "Alaska", "Arizona"],
      chartData1: [],
      chartData2: null,

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      alert_news: null,
    };
  },
  methods: {
    changeFilter(arg) {},
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 3; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    dashboard() {
      this.loading = true;
      axios
        .get(
          this.$store.state.backend_url + "api/dashboard/" + this.$i18n.locale
        )
        .then((res) => {
          let data = res.data.boxes;
          this.report_by_date = res.data.report_by_date;
          this.my_reysters = res.data.my_reysters.filter(
            (v) => v.documents_count > 0
          );
          this.chartData1 = data.map((v) => ({
            count: v.count,
            new_count: v.new_count,
            name: this.$t("message." + v.name),
            originalName: v.name,
          }));
          this.catalog.inbox = this.chartData1[0];
          this.catalog.outbox = this.chartData1[1];
          this.catalog.draft = this.chartData1[2];
          this.catalog.cancel = this.chartData1[3];
          let labels = data.map((v) => this.$t("message." + v.name));
          let counts = data.map((v) => v.count);
          let colors = data.map((v) => this.getRandomColor());
          this.chartData2 = {
            labels: labels,
            datasets: [
              {
                backgroundColor: ["teal", "indigo", "blue", "orange", "grey"],
                data: counts,
              },
            ],
          };

          //---ma-1
          this.document_types = res.data.document_types;
          this.departments = res.data.departments;
          this.document_types.forEach((element) => {
            this.count_document += element.counter;
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDocumentList() {
      axios
        .get(this.$store.state.backend_url + "api/documents/list")
        .then((res) => {
          this.document_lists = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDocumentSignerReport() {
      this.loading = true;
      axios
        .get(this.$store.state.backend_url + "api/documents/signer-report")
        .then((res) => {
          // this.all_count = res.data.all_count[0];
          // this.doc_eri = res.data.doc_eri[0];
          // this.doc_ad = res.data.doc_ad[0];
          // console.log("ffffff", this.all_count);
          // console.log("ffffff", this.doc_eri);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserReport() {
      this.loading = true;
      axios
        .get(this.$store.state.backend_url + "api/users/report")
        .then((res) => {
          this.all_count = res.data.all_count;
          this.doc_eri = res.data.doc_eri;
          this.doc_ad = res.data.doc_ad;
          this.user_all_count = res.data.user_all_count;
          this.user_eri = res.data.user_eri;
          this.user_ad = res.data.user_ad;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {},
    updateRange({ start, end }) {},
    calendarData() {
      alert("working..");
      this.calendarSection = true;
    },
  },
  mounted() {
    this.dashboard();
    this.getDocumentSignerReport();
    this.getUserReport();
    // axios
    //   .get(
    //     this.$store.state.backend_url +
    //       "api/documents/notification/" +
    //       this.$i18n.locale
    //   )
    //   .then((res) => {
    //     // this.notifications = res.data;
    //     // if(this.notifications.alert && this.notifications.alert.length)
    //     //   alert(this.notifications.alert);
    //     // this.$store.dispatch("setNotifications", res.data);
    //     let alert =
    //       res.data.alert.length > 0
    //         ? ("| " + res.data.alert[0].content + " |")
    //         : "";

    //     // res.data.alert.map(v => {
    //     //   alert = "| " + v.content + " |" + alert;
    //     // });
    //     this.alert_news = alert;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // this.$refs.calendar.checkChange();
    if(this.$store.state.notifications.alert){
      this.alert_news = '| '
      this.$store.state.notifications.alert.forEach(element => {
        this.alert_news += element.content + ' | ';
      });
    }
  },
};
</script>
<style scoped>
.dashboard_bg {
  background: #fff;
}
.dashboard__title {
  color: #205c7c;
  font-size: 40px;
  font-weight: 700;
  text-shadow: 0 2px 3px rgba(60, 60, 60, 0.25);
}
.dashboard__togglers {
  display: flex;
  justify-content: center;
}
.dashborad_date_toggler {
  background: transparent;
  /* border: 1px solid #205c7c; */
  /* border-radius: 18px; */
  pointer-events: initial;
  overflow: hidden;
}
.dashboard_manager_toggler {
  background: transparent;
  /* border: 1px solid #205c7c; */
  /* border-radius: 18px; */
  pointer-events: initial;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.dashboard__togglers-toggler-item {
  color: #205c7c;
  height: 34px;
  text-transform: capitalize;
  font-family: "Montserrat", Sans-serif;
}
.v-input__slot fieldset {
  border: none !important;
}
.v-text-field__slot {
  color: #fff !important;
}
.card_title {
  color: #000;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.03em;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 41px;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
.card_sub--title {
  font-size: 13px;
  line-height: 10px;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  color: #000;
  line-height: 1.2;
}
.card_title-number {
  font-size: 43px;
  font-weight: 600;
  color: #8275ff;
}
.card_title-sub--number {
  font-size: 19px;
  font-weight: 600;
  color: #00d669;
}
.text-ellipsis {
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.top-chart__pipeline {
  margin-right: 15px;
}
.top-chart__pipelines-item {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 18px;
  color: #205c7c;
  text-decoration: none;
}
.top-chart_title {
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* text-shadow: 0 2px 3px rgba(0, 0, 0, 0.25); */
}
.top-chart_sub--title {
  color: #205c7c !important;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}
.top-chart-number {
  display: block;
  color: #8275ff;
  text-align: center;
  font-weight: 600;
  font-size: 45px;
  /* text-shadow: 0 2px 3px rgba(0, 0, 0, 0.25); */
  margin-top: -10px;
}
.top-chart-sub--number {
  /* color: #000 !important; */
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  /* text-shadow: 0 2px 3px rgba(0, 0, 0, 0.25); */
}
.top-chart_btn {
  color: #205c7c;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
#top-chart-number {
  color: #205c7c;
}
#top-chart-sub--number {
  color: #205c7c;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  /* text-shadow: 0 2px 3px rgba(0, 0, 0, 0.25); */
}
.fullHeight {
  height: calc(100% - 0px);
}
.heightFull {
  height: 100%;
}
.fontSize35 {
  font-size: 30px;
}
</style>
